/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'
import { formatDateViewToDb } from '@/utils/helpers'
import LocalStorageService from '@/services/localStorageService'

export default class UserService {
  list(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/users?page=${page}`, params)
  }

  checkRepByCore(core) {
    const num = core.replace(/[^0-9]/, '')
    return useJwt.getExternal(`https://consultarep.confere.org.br/Representantes/ConsultaPorRegistro?param=${num}`)
  }

  checkRepByCpfCnpj(doc) {
    const num = doc.replace(/[^0-9]/, '')
    return useJwt.getExternal(`https://consultarep.confere.org.br/Representantes/ConsultaPorCpfCnpj?param=${num}`)
  }

  checkRepByName(name) {
    return useJwt.getExternal(`https://consultarep.confere.org.br/Representantes/ConsultaPorNome?param=${name}`)
  }

  checkCnpj(cnpj) {
    const num = cnpj ? cnpj.replace(/[^0-9]/g, '') : ''
    return useJwt.get(`/user/cnpj/${num}`)
  }

  getAudits(userId, nearOf) {
    return useJwt.get(`/user/audit/${userId}/${nearOf}`)
  }

  search(filters) {
    return useJwt.get('/buscar', filters)
  }

  sendSearch(id, params) {
    return useJwt.post(`/user/send-search/${id}`, {}, params)
  }

  getSavedSearches(userId) {
    return useJwt.get(`/user/saved-searches/${userId}`)
  }

  saveSearch(userId, params) {
    return useJwt.post(`/user/save-search/${userId}`, {}, params)
  }

  listActiveUsers(emails, page = 0) {
    const params = {
      page,
      emails: [],
    }
    emails.forEach(email => {
      params.emails.push(email)
    })
    return useJwt.get('/active-users', params)
  }

  migrate(userFrom, userTo, data) {
    return useJwt.post(`/user/migrate/${userFrom}/${userTo}`, {}, data)
  }

  disableSmarties(userId) {
    return useJwt.delete(`/user/smarties/${userId}`)
  }

  setTuro(daysWithoutLogin, daysWithoutMessage, daysWithoutOpenEmail) {
    const args = {}
    args['days-without-login'] = daysWithoutLogin
    args['days-without-message'] = daysWithoutMessage
    args['days-without-open-email'] = daysWithoutOpenEmail
    return useJwt.post('/user/set-turo', {}, args)
  }

  listTuro(daysWithoutLogin, daysWithoutMessage, daysWithoutOpenEmail, currentPage) {
    const args = {}
    args['days-without-login'] = daysWithoutLogin
    args['days-without-message'] = daysWithoutMessage
    args['days-without-open-email'] = daysWithoutOpenEmail
    args.page = currentPage

    return useJwt.get('/user/turo', args)
  }

  listSmarties(dateFrom, dateTo, page) {
    const args = {}
    if (dateFrom.length > 0) {
      args['date-from'] = dateFrom
    }

    if (dateTo.length > 0) {
      args['date-to'] = dateTo
    }

    if (page !== undefined && page != null) {
      args.page = page
    }

    return useJwt.get('/user/smarties', args)
  }

  listExtractedPhones(userId) {
    return useJwt.get(`/user/extracted-phones/${userId}`)
  }

  removeExtractedPhone(id) {
    return useJwt.delete(`/user/extracted-phone/${id}`)
  }

  listActivities(userId, type = null) {
    return useJwt.get(`/user/activities/${userId}`, {
      ...type ? { type } : {},
    })
  }

  saveFile(userId, data) {
    return useJwt.post(`/user/file/${userId}`, {}, data)
  }

  removeFile(userId) {
    return useJwt.delete(`/user/file/${userId}`, {})
  }

  saveAvatar(userId, data) {
    return useJwt.post(`/user/avatar/${userId}`, {}, data)
  }

  directIndication(userId, data) {
    return useJwt.post(`/user/direct-indication/${userId}`, {}, data)
  }

  rotateAvatar90(userId) {
    return useJwt.post(`/user/avatar-rotate/${userId}`, {}, { angle: 90 })
  }

  rotateAvatar270(userId) {
    return useJwt.post(`/user/avatar-rotate/${userId}`, {}, { angle: 270 })
  }

  removeAvatar(userId, data) {
    return useJwt.delete(`/user/avatar/${userId}`, {}, data)
  }

  listFavorites(userId, page, order = '', desc = false) {
    const direction = desc ? ' DESC' : ''
    return useJwt.get(`/user/favorites/${userId}?page=${page}&order_by=${order} ${direction}`, {})
  }

  deleteFavorite(favoriteId) {
    return useJwt.delete(`/user/favorites/${favoriteId}`, {})
  }

  getTaxData(userId) {
    return useJwt.get(`/user/tax-data/${userId}`, {})
  }

  updateTaxData(userId, data) {
    return useJwt.post(`/user/tax-data/${userId}`, data)
  }

  listQuotas(userId) {
    return useJwt.get(`/user/quotas/${userId}`, {})
  }

  addAnnotation(userId, annotation) {
    return useJwt.post(`/user/annotation/${userId}`, {}, annotation)
  }

  updateAnnotation(id, annotation) {
    return useJwt.put(`/user/annotation/${id}`, {}, annotation)
  }

  listAnnotations(userId, page) {
    return useJwt.get(`/user/annotations/${userId}?page=${page}`, {})
  }

  deleteJourney(journeyId) {
    return useJwt.delete(`/user/journey/${journeyId}`, {})
  }

  listSettings(userId) {
    return useJwt.get(`/user/settings/${userId}`, {})
  }

  updateCercManager(userId, data) {
    return useJwt.put(`/user/settings/cerc-manager/${userId}`, {}, data)
  }

  createPayment(userId, planId, method, discount, turbo, expiresAt, productId) {
    return useJwt.post(`/user/create-payment/${userId}`, {}, {
      planId,
      method,
      discount,
      turbo,
      expires_at: expiresAt,
      product_id: productId,
    })
  }

  sendChagerFromTransaction(transactionId, email) {
    return useJwt.post(`/user/email/send-charge-from-transaction/${transactionId}`, {}, { email })
  }

  updateSettings(userId, data) {
    return useJwt.post(`/user/settings/${userId}`, {}, data)
  }

  runAllValidations(userId) {
    return useJwt.post(`/user/review/registration-automation/run/${userId}`, {})
  }

  updateDemographicCompany(userId, data) {
    return useJwt.post(`/user/demographic/company/${userId}`, {}, data)
  }

  updateDemographicRepresentative(userId, data) {
    return useJwt.post(`/user/demographic/representative/${userId}`, {}, data)
  }

  getDemographic(userId) {
    return useJwt.get(`/user/demographic/${userId}`, {})
  }

  listManagers() {
    return useJwt.get('/managers', {})
  }

  getAndStoreManagers() {
    if (LocalStorageService.getArray(LocalStorageService.managers) !== null) {
      return new Promise(resolve => {
        resolve(LocalStorageService.getArray(LocalStorageService.managers))
      })
    }

    return this.listManagers().then(response => {
      if (response.data !== null) {
        let { managers } = response.data.data
        managers = Object.values(managers).sort((a, b) => a.name.localeCompare(b.name))
        LocalStorageService.setArray(LocalStorageService.managers, managers)
        return managers
      }

      return []
    })
  }

  getWhatsappCampaigns(userId) {
    return useJwt.get(`/user/whatsapp-campaign/${userId}`)
  }

  deleteWhatsappCampaign(campaignId) {
    return useJwt.delete(`/user/whatsapp-campaign/${campaignId}`)
  }

  delete(id) {
    // REFAZER
    // REFAZER
    // REFAZER
    return useJwt.delete(`/user/${id}`)
  }

  post(data) {
    // REFAZER
    // REFAZER
    // REFAZER
    const newData = {
      ...data,
      users: data.users.split('\n'),
      send_in: formatDateViewToDb(data.send_in),
    }

    return useJwt.post('/user', {}, newData)
  }

  get(id) {
    return useJwt.get(`/user/${id}`)
  }

  getDoubleRegistrations(params) {
    return useJwt.get('/user/double-registrations', params)
  }

  getStatistics(id, rangeStart, rangeEnd) {
    return useJwt.get(`/user/statistics/${id}?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`)
  }

  getAllInformations(id) {
    return useJwt.get(`/user/${id}?columns=complete`)
  }

  sendEmailToEasyLogin(id) {
    return useJwt.post(`/user/email-direct-access/${id}`, {})
  }

  blockedDoubleResolve(id) {
    return useJwt.post(`/user/blocked-double-resolve/${id}`, {})
  }

  sendEmailToRecoverPassword(id, type) {
    return useJwt.post(`/user/email-password-recovery/${id}/${type}`, {})
  }

  migrateLogin(id) {
    return useJwt.post(`/user/migrate-login/${id}`, {})
  }

  sendConfirmationWhatsApp(id) {
    return useJwt.post(`/user/send-confirmation-whatsapp/${id}`, {})
  }

  confirmWhatsApp(id) {
    return useJwt.post(`/user/confirm-whatsapp/${id}`, {})
  }

  sendConfirmationEmail(id) {
    return useJwt.post(`/user/send-confirmation-email/${id}`, {})
  }

  confirmEmail(id) {
    return useJwt.post(`/user/confirm-email/${id}`, {})
  }

  updateRegistration(id, data) {
    return useJwt.post(`/user/registration-info/${id}`, {}, data)
  }

  updateSearchProfile(id, data) {
    return useJwt.post(`/user/search-profile/${id}`, {}, data)
  }

  updateReview(id, type, data) {
    return useJwt.post(`/user/${type}/primary/${id}`, {}, data)
  }

  updateReviewCommercial(id, type, data) {
    return useJwt.post(`/user/${type}/profile/${id}`, {}, data)
  }

  updateReviewZones(id, type, data) {
    return useJwt.post(`/user/${type}/zones/${id}`, {}, data)
  }

  updateReviewAdditions(id, type, data) {
    return useJwt.post(`/user/${type}/additions/${id}`, {}, data)
  }

  updateReviewSocials(id, type, data) {
    return useJwt.post(`/user/${type}/socials/${id}`, {}, data)
  }

  updateReviewApproval(id, type, data) {
    return useJwt.post(`/user/${type}/approval/${id}`, {}, data)
  }

  assignTo(data, force) {
    if (force) {
      return useJwt.post('/user/force-assign-to', {}, data)
    }

    return useJwt.post('/user/assign-to', {}, data)
  }

  login() {
    return 'url'
  }

  peloId(id) {
    return useJwt.get(`/pelo-id/${id}`)
  }

  static getHealthScoreFromPayingUsers = filters => useJwt.post('/user/health-scores', {}, filters)

  static getHealthScoreFromUser = ({ user_id: userId }) => useJwt.post(`/user/health-scores/${userId}`)

  static removeManager(userId, type) {
    return useJwt.delete(`/user/remove-manager/${userId}/${type}`)
  }

  static cercRequestUser(userId) {
    return useJwt.post(`/user/cerc-request-user/${userId}`)
  }

  static quickSearch(params) {
    return useJwt.get('/quick-search', params)
  }

  static listRatings(dateFrom, dateTo, page) {
    return useJwt.get(`/user/ratings?page=${page}`, { date_from: dateFrom, date_to: dateTo })
  }
}
